import { OAuthModuleConfig } from 'angular-oauth2-oidc';

export const oAuthModuleConfig: OAuthModuleConfig = {
    resourceServer: {
        allowedUrls: [
            'https://sso.dev.almaphil.de',
            // 'https://sso.dev.almaphil.de/oauth2',
            // 'https://sso.dev.almaphil.de/oauth2/token',
        ],
        sendAccessToken: true,
    },
};
