import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';

export const authConfig: AuthConfig = {
    issuer: environment.ssoUrl,
    clientId: 'alma-web-app', // The "Auth Code + PKCE" client
    // dummyClientSecret: 'b73b5af93ca52c0f',
    strictDiscoveryDocumentValidation: false,
    responseType: 'code',
    // responseType: 'id_token token',
    redirectUri: window.location.origin + '/',
    scope: 'openid profile email', // Ask offline_access to support refresh token refreshes
    useSilentRefresh: true,
    // Needed for Code Flow to suggest using iframe-based refreshes
    // silentRefreshRedirectUri: window.location.origin + '/silent-refresh',
    // silentRefreshTimeout: 5000, // For faster testing
    //   timeoutFactor: 0.25, // For faster testing
    //   sessionChecksEnabled: true,
    requestAccessToken: true,
    // tokenEndpoint: 'https://sso.dev.almaphil.de/oauth2/token',
    showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
    //   clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
    //   nonceStateSeparator : 'semicolon' // Real semicolon gets mangled by Duende ID Server's URI encoding
    // logoutUrl,
    // postLogoutRedirectUri: window.location.origin + '/sign-in',
    // postLogoutRedirectUri: window.location.origin,
    // redirectUriAsPostLogoutRedirectUriFallback
};
