import { inject, Injectable } from '@angular/core';
import { FuseConfig, FuseConfigService } from '@fuse/services/config';
import { AssetsSourcePipe } from '../pipes/assets.pipe';
import { Title } from '@angular/platform-browser';
import { AssetsService } from './assets.service';
import { authConfig } from 'app/core/auth/auth.config';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
    config: FuseConfig;
    subDomain: string = window.location.hostname.split('.')[0];

    assets = inject(AssetsService).assets;

    /**
     * Constructor
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private titleService: Title
    ) {}

    setConfiguration() {
        this.subDomain =
            this.subDomain === 'localhost' ? 'adele' : this.subDomain;
        console.log(this.subDomain);

        // authConfig.redirectUri =
        //     window.location.origin + '&company_id=' + this.subDomain;

        const theme = `theme-${this.subDomain}`;
        this._fuseConfigService.config = { theme };

        // Title
        this.titleService.setTitle(this.assets[this.subDomain].title);

        // Assign a constant variable and get them by the favicon Id
        const favicon = document.getElementById('favicon');
        const assetsPipe = new AssetsSourcePipe();
        favicon.setAttribute('href', assetsPipe.transform(`favicon`));

        document.documentElement.style.setProperty(
            '--font-family',
            this.assets[this.subDomain].fontFamilty
        );
        document.documentElement.style.setProperty(
            'font-family',
            this.assets[this.subDomain].fontFamilty
        );
    }
}
