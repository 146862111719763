import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AssetsService {
    assets = {
        adele: {
            logo: 'logo.svg',
            'logo-panel': 'logo-white.svg',
            'logo-splash-screen': 'logo-white.svg',
            favicon: 'favicon-32x32.png',
            title: 'ADELE',
            signinTitle: 'WELCOME',
            signinDesc: 'ADELE-IS-YOUR-PERSONAL-HEALTH-ASSISTANT',
            fontFamilty:
                '"Montserrat", ui-sans-serif,system-ui,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
        },
        juh: {
            logo: 'logo.svg',
            'logo-panel': 'logo.svg',
            'logo-splash-screen': 'logo.svg',
            favicon: 'favicon.ico',
            title: 'JOHANNITER',
            signinTitle: 'WELCOME',
            signinDesc: 'JUH-IS-YOUR-PERSONAL-HEALTH-ASSISTANT',
            fontFamilty:
                '"Maven Pro", ui-sans-serif,system-ui,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
        },
    };

    /**
     * Constructor
     */
    constructor() {}
}
