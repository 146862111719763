import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from './core/auth/auth.service';
import { SplashScreenComponent } from './modules/shared/containers/splash-screen/splash-screen.component';
import { ConfigurationService } from './modules/shared/services/configuration.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, SplashScreenComponent],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        private _config: ConfigurationService,
        private _authService: AuthService
    ) {
        console.log('*** init App component ***');
        this._config.setConfiguration();
        _authService.configureSingleSignOn();
        // _authService.configureSingleSignOn2();
    }
}
